<template>
  <div class="cahier  ml-16 mr-16 mt-5">
    <h2>15</h2>
    Écoutez et répondez. <Tooltip label="…の中で">Parmi</Tooltip> les phrases suivantes, dites lesquelles caractérisent le fonctionnement d'une SCOP.
    <ol type="a" class="mt-5">
      <li>Les coopératives ne sont pas faites pour gagner de l'argent.</li>
      <li>L'argent gagné par l'<Tooltip label="会社">entreprise</Tooltip> est <Tooltip label="再分配">redistribué</Tooltip> à égalité entre les <Tooltip label="給与生活者">salariés</Tooltip></li>
      <li>L'entreprise <Tooltip label="属する">appartient</Tooltip> à des <Tooltip label="投資家">investisseurs</Tooltip> étrangers.</li>
      <li>Les salariés sont <Tooltip label="所有者">propriétaires</Tooltip> de la majorité de l'entreprise.</li>
      <li>Les salariés <Tooltip label="投票する">votent</Tooltip> pour <Tooltip label="指名する">désigner</Tooltip> le directeur de la coopérative.</li>
      <li>Le premier ministre désigne le président de la SCOP.</li>
      <li>Les décisions sont prises <Tooltip label="給与生活者の合議によって">par l'ensemble des salariés</Tooltip>.</li>
      <li>Le <Tooltip label="経営者">patron</Tooltip> prend les décisions dans l’entreprise.</li>
    </ol>

    <v-card class="ml-10 mr-10 mt-10 mb-10">
      <v-card-title>
        Lexique
      </v-card-title>
      <v-card-text>
        <p>
          L'acronyme « SCOP » signifie « Société COopérative et Participative ».
        </p>
        <p>
          L'acronyme « OPA » signifie « <Tooltip label="株式公開買い付け">Offre Publique d'Achat</Tooltip> ». 
        </p>
        <p>
          Elle désigne une opération financière qui consiste à <Tooltip label="買い戻す">racheter</Tooltip> <Tooltip label="十分に">suffisamment</Tooltip> d'actions en <Tooltip label="証券取引">bourse</Tooltip> pour prendre le contrôle sur une entreprise.
        </p>
      </v-card-text>
    </v-card>

    <h2>16</h2>
    Écoutez la 2<sup>e</sup> <Tooltip label="部分">partie</Tooltip> du document et dites si les phrases suivantes sur l'entreprise Unox sont vraise ou fausses.
    <ol type="a" class="mt-5">
      <li>La <Tooltip label="創設">création</Tooltip> de l'entreprise est <Tooltip label="最近の">récente</Tooltip>, une <Tooltip label="１０">dizaine</Tooltip> d'années.</li>
      <li>Les fondateurs ont <Tooltip label="創設する">créé</Tooltip> cette entreprise pour en faire un exemple.</li>
      <li>L'entreprise a été créée par un groupe de personnes qui voulaient travailler de façon <Tooltip label="共同の">collective</Tooltip>.</li>
      <li>La décision collective <Tooltip label="複雑な">complique</Tooltip> la <Tooltip label="経営">gestion</Tooltip> de l'entreprise.</li>
      <li>Les gens ont généralement une bonne opinion des SCOP.</li>
      <li>L'entreprise a de bons résulltats et progresse.</li>
    </ol>

    <h3 class="mt-5">document pour 15, 16</h3>
    <p> 
      - C'est un modèle qui existe depuis le XIX<sup>e</sup> siècle mais qui connaît ces dernières années un <Tooltip label="回復">regain</Tooltip> d'<Tooltip label="興味">intérête</Tooltip> en France : celui des SCOP,
      les sociétés coopératives et participatives où les salariés <Tooltip label="保有する">détiennent</Tooltip> le capital, 
      et se partagent de manière <Tooltip label="公平な">équitable</Tooltip> les <Tooltip label="利益">bénéfices</Tooltip>.
    </p>
    <p>
      Elles sont aujourd'hui un peu moins de 2000, et <Tooltip label="一つにまとめる">regroupent</Tooltip> <Tooltip label="訳、およそ">environ</Tooltip> 40 000 salarié. 
      À l'<Tooltip label="規模">échelle</Tooltip> de l'économie française, c'est <Tooltip label="少ない">peu</Tooltip> <Tooltip label="もちろん">bien sûr</Tooltip>, 
      mais avec la crise un nombre croissant d'entrepreneurs <Tooltip label="興味を持つ">s'intéresse</Tooltip> à ce modèle.
    </p>
    <p>
      « Tous patrons, tous salariés : la renaissance des coopératives de production »,
      un grand reportage <Tooltip label="署名入りで書く">signé</Tooltip> Daniel Vallot, Daniel que l'on retrouve en direct pour nous en parler dans un quart d'heure.
    </p>
    <p>
      - Dans une SCOP, il faut gagner de l'argent pour continuer à exister.
      De ce point de vue, une coopérative de production c'est une entreprise comme les autres.
      Pour <Tooltip label="残り">le reste</Tooltip>, tout ou <Tooltip label="ほとんど">presque</Tooltip> est différent, <Tooltip label="…を始めとして">à commencer par</Tooltip> le partage équitable des bénéfices :
      une partie revient aux salariés , le reste est mis de <Tooltip label="側">côté</Tooltip> pour <Tooltip label="保証する">assurer</Tooltip> la <Tooltip label="存続">pérennité</Tooltip> de l'entreprise.
      Autre différence : le capital doit être détenu à majourité par les salariés ce qui <Tooltip label="排除する">exclut</Tooltip> toute possibilité de <Tooltip label="???">délocalisation</Tooltip> ou d'OPA.
    </p>
    <p>
      Un autre élément à <Tooltip label="質問する、問題を提起する">peser</Tooltip> : la démocratie <Tooltip label="内部の">interne</Tooltip> <Tooltip label="結びつく">lié</Tooltip> au <Tooltip label="身分規定">statut</Tooltip> <Tooltip label="協力的な">coopératif</Tooltip>. 
      Dans les <Tooltip label="総会">assemblées générales</Tooltip>, chaque <Tooltip label="会員">sociétaire</Tooltip> a le même <Tooltip label="重み">poids</Tooltip> quelque soit son <Tooltip label="寄与">apport</Tooltip> au capital.
      <Tooltip label="…については">Quant au</Tooltip> <Tooltip label="幹部">dirigeant</Tooltip> de la SCOP, il est <Tooltip label="[élire]選出する">élu</Tooltip> et c'est un <Tooltip label="[salarier]給与を支払う">salarié</Tooltip> comme les autres.
      François Mélan est l'un des fondateurs de l'agence web Unox, créée en 2002, sur ce modèle.
      FRANÇOIS MÉLAN. - On avait envie de <Tooltip label="設立する">monter</Tooltip> notre <Tooltip label="自身の">propre</Tooltip> <Tooltip label="職場">boîte</Tooltip>, de la <Tooltip label="指導する">diriger</Tooltip> ensemble et de savoir où elle allait.
      Donc c'est un peu l'inverse du modèle du petit patron qui va décider pour ses salariés. 
      Nous, on voulait quelque chose de plus démocratique et plus <Tooltip label="透明な">transparent</Tooltip>.
    </p>
    <p>
      JOURNALISTE. - Alors est-ce que c'est pas un <Tooltip label="ブレーキ">frein</Tooltip> <Tooltip label="取得">la prise de</Tooltip> décision collective comme ça pour une entreprise ?
    </p>
    <p>
      F.M. - Alors ça, c'est également l'image que les gens ont <Tooltip label="しばしば">souvent</Tooltip>.
      C'est que « Oh ! Là, là ! Ça doit prendre de temps », vous devez jamais rien <Tooltip label="決めつける">décider</Tooltip>, c'est l'assemblée générale <Tooltip label="常設の">permanente</Tooltip>.
      On a même entendu, au moment où on se créait, un <Tooltip label="銀行家">banquier</Tooltip> qui nous a dit « moi je n'y <Tooltip label="信じる">crois</Tooltip> pas à votre projet, dans une <Tooltip label="ニワトリ小屋">basse-cour</Tooltip> il faut un seul coq ». 
      Donc ça c'est, voila, c'est une façon idéologique de <Tooltip label="理解する">concevoir</Tooltip> les choses, mais c'est pas vrai.
      Non ça fonctionne très <Tooltip label="有効な">efficace</Tooltip> si on en <Tooltip label="判断する">juge</Tooltip> par nos <Tooltip label="売上">chiffres</Tooltip>, 
      donc on est à 1 million 5 de <Tooltip label="売上総額">chiffre d'affaires</Tooltip> sur l'année dernière, avec une progression de 15-20 %. 
      On est … On est très <Tooltip label="収益性がある">rentables</Tooltip>.
    </p>
  </div>
</template>

<script>
import Tooltip from '@/components/Tooltip'
export default {
  name: 'Cahier',
  components: {Tooltip},
}
</script>
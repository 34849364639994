<template>
  <div class="home ml-16 mr-16 mt-5">
<h1>WRITE SIMPLE</h1>
<h4>March 2021</h4>

<p class="mt-5">I <Tooltip label="努める">try</Tooltip> to write using ordinary words and simple sentences.</p>
<p><Tooltip label="そのような">That kind of</Tooltip> writing is easier to read, and the easier something is to read, the more deeply readers will <Tooltip label="噛み合わせる">engage with</Tooltip> it. 
The less energy they expend on your <Tooltip label="散文">prose</Tooltip>, the more they'll have left for your ideas.</p>

<p>And the further they'll read. Most readers' energy tends to <Tooltip label="途中で">flag</Tooltip> <Tooltip label="途中で">part way</Tooltip> through an article or essay. 
If the friction of reading is low enough, more keep going till the end.</p>

<p>There's an Italian dish called saltimbocca, which means "leap into the mouth." My goal when writing might be called saltintesta: the ideas leap into your head and you barely notice the words that got them there.</p>

<p>It's too much to hope that writing could ever be pure ideas. 
  You might not even want it to be. 
  But for most writers, most of the time, that's the goal to aim for. 
  The gap between most writing and pure ideas is not filled with poetry.</p>

<p>Plus it's more considerate to write simply. 
  When you write in a fancy way to impress people, you're making them do extra work just so you can seem cool. 
  It's like trailing a long train behind you that readers have to carry.</p>

<p>And remember, if you're writing in English, that a lot of your readers won't be native English speakers. 
  Their understanding of ideas may be <Tooltip label="[…より]ずっと先に">way ahead of</Tooltip> their understanding of English. 
  So you can't assume that writing about a difficult topic means you can use difficult words.</p>

<p>Of course, fancy writing doesn't just conceal ideas. 
  It can also conceal the lack of them. 
  That's why some people write that way, to conceal the fact that they have nothing to say. 
  <Tooltip label="であるがゆえに">Whereas</Tooltip> writing simply keeps you honest. 
  If you say nothing simply, it will be obvious to everyone, including you.</p>

<p>Simple writing also <Tooltip label="持続する">lasts</Tooltip> better. 
People reading your stuff in the future will be in much the same position as people from other countries reading it today. 
The culture and the language will have changed. 
It's not vain to care about that, any more than it's <Tooltip label="中身のない">vain</Tooltip> for a woodworker to build a chair to last.</p>

<p>Indeed, lasting is not merely an accidental quality of chairs, or writing. 
  It's a sign you did a good job.</p>

<p>But although these are all real advantages of writing simply, none of them are why I do it. 
  The main reason I write simply is that it <Tooltip label="[人の]気分を害する、怒らせる">offends</Tooltip> me not to. 
  When I write a sentence that seems too complicated, or that uses unnecessarily intellectual words, it doesn't seem fancy to me. 
  It seems <Tooltip label="不格好な">clumsy</Tooltip>.</p>

<p>There are of course times when you want to use a complicated sentence or fancy word for effect. 
  But you should never do it by accident.</p>

<p>The other reason my writing ends up being simple is the way I do it. 
  I write the first draft fast, then spend days editing it, trying to get everything just right. 
  Much of this editing is cutting, and that makes simple writing even simpler.</p>

  </div>
</template>

<style>
  .home{
    text-align: left;
  }
  p {
   text-indent: 1em;
  }
  blockquote {
    position: relative;
    padding: 5px;
    box-sizing: border-box;
    font-style: italic;
  }
  dt {
		margin-top: 1em;
		clear: left;
	}
	dd {
		margin-left: 1em;
    margin-bottom: 1em;
	}
</style>

<script>
import Tooltip from '@/components/Tooltip'
export default {
  name: 'Home',
  components: {Tooltip},
}
</script>
<template>
  <div class="home ml-16 mr-16 mt-5">

<h1>INTRODUCTION.</h1>

<p>THE share which worms have <Tooltip label="[…の]内職をする">taken in</Tooltip> the formation of the layer of vegetable mould, which covers the whole surface of the land in every moderately humid country, is the subject of the present volume. 
This mould is generally of a blackish colour and a few inches in thickness. In different districts it differs but little in appearance, although it may rest on various subsoils. The uniform fineness of the particles of which it is composed is one of its chief characteristic features; and this may be well observed in any gravelly country, where a recently-ploughed field immediately adjoins one which has long remained undisturbed for pasture, and where the vegetable mould is exposed on the sides of a ditch or hole. The subject may appear an insignificant one, but we shall see that it possesses some interest; and the maxim "de minimis lex non curat," does not apply to science. Even Elie de Beaumont, who generally undervalues small agencies and their accumulated effects, remarks.<a href="#footnote-1"><sup>*</sup></a> "la couche très-mince de la terre végétale est un monument d'une haute antiquité, et, par le fait de sa permanence, un objet digne d'occuper le géologue, et capable de lui fournir des remarques intéressantes." Although the superficial layer of vegetable mould as a whole no doubt is of the highest antiquity, yet in regard to its permanence, we shall hereafter see reason to believe that its component particles are in most cases removed at not a very slow rate, and are replaced by others due to the disintegration of the underlying materials.</p>

<p id="footnote-1">* 'Leçons de Géologie Pratique,' tom. i. 1845, p. 140.</p>

<p>As I was led to keep in my study during many months worms in pots filled with earth, I became interested in them, and wished to learn how far they acted consciously, and how much mental power they displayed. I was the more desirous to learn something on this head, as few observations of this kind have been made, as far as I know, on animals so low in the scale of organization and so poorly provided with sense-organs, as are earth-worms.</p>

<p>In the year 1837, a short paper was read by me before the Geological Society of London,<a href="#footnote-2"><sup>*</sup></a> "On the Formation of Mould," in which it was shown that small fragments of burnt marl, cinders, &c., which had been thickly strewed over the surface of several meadows, were found after a few years lying at the depth of some inches beneath the turf, but still forming a layer. This apparent sinking of superficial bodies is due, as was first suggested to me by Mr. Wedgwood of Maer Hall in Staffordshire, to the large quantity of fine earth continually brought up to the surface by worms in the form of castings. These castings are sooner or later</p>
<p id="footnote-2">* 'Transactions Geolog. Soc.' vol. v. p. 505. Read November 1, 1837.</p>
<p>spread out and cover up any object left on the surface. I was thus led to conclude that all the vegetable mould over the whole country has passed many times through, and will again pass many times through, the intestinal canals of worms. Hence the term "animal mould" would be in some respects more appropriate than that commonly used of "vegetable mould."</p>
<p>Ten years after the publication of my paper, M. D' Archiac, evidently influenced by the doctrines of Elie de Beaumont, wrote about my "singulière théorie," and objected that it could apply only to "les prairies basses et humides;" and that "les terres labourées, les bois, les prairies élevées, n'apportent aucune preuve à l'appui de cette manière de voir."<a href="#footnote-3"><sup>*</sup></a> But M. D' Archiac must have thus argued from inner consciousness and not from observation, for worms abound to an extraordinary degree in kitchen gardens where the soil is continually worked, though in such loose soil they generally deposit their castings in any open cavities or within their old burrows instead of on the surface. Von Hensen estimates that there are</p>

<p id="footnote-3">* 'Histoire des progrès de la Géologic,' tom. i. 1847, p. 224.</p>
<p>about twice as many worms in gardens as in corn-fields.<a href="#footnote-4"><sup>*</sup></a> With respect to "prairies élevées," I do not know how it may be in France, but nowhere in England have I seen the ground so thickly covered with castings as on commons, at a height of several hundred feet above the sea. In woods again, if the loose leaves in autumn are removed, the whole surface will be found strewed with castings. Dr. King, the superintendent of the Botanic Garden in Calcutta, to whose kindness I am indebted for many observations on earthworms, informs me that he found, near Nancy in France, the bottom of the State forests covered over many acres with a spongy layer, composed of dead leaves and innumerable worm-castings. He there heard the Professor of "Aménagement des Forêts" lecturing to his pupils, and pointing out this case as a "beautiful example of the natural cultivation of the soil; for year after year the thrown-up castings cover the dead leaves; the result being a rich humus of great thickness."</p>
<p id="footnote-4">* 'Zeitschrift für wissenschaft. Zoologie,' B. xxviii. 1877, p. 361.</p>
<p>In the year 1869, Mr. Fish<a href="#footnote-5"><sup>*</sup></a> rejected my conclusions with respect to the part which worms have played in the formation of vegetable mould, merely on account of their assumed incapacity to do so much work. He remarks that "considering their weakness and their size, the work they are represented to have accomplished is stupendous." Here we have an instance of that inability to sum up the effects of a continually recurrent cause, which has often retarded the progress of science, as formerly in the case of geology, and more recently in that of the principle of evolution.</p>
<p>Although these several objections seemed to me to have no weight, yet I resolved to make more observations of the same kind as those published, and to attack the problem on another side; namely, to weigh all the castings thrown up within a given time in a measured space, instead of ascertaining the rate at which objects left on the surface were buried by worms. But some of my observations have been rendered almost superfluous by an admirable paper by Von Hensen,</p>
<p id="footnote-5">* 'Gardeners' Chronicle,' April 17, 1869, p. 418.</p>
<p>already alluded to, which appeared in 1877. Before entering on details with respect to the castings, it will be advisable to give some account of the habits of worms from my own observations and from those of other naturalists.</p>

  </div>
</template>

<style>
  .home{
    text-align: left;
  }
  p {
   text-indent: 1em;
  }
  blockquote {
    position: relative;
    padding: 5px;
    box-sizing: border-box;
    font-style: italic;
  }
  dt {
		margin-top: 1em;
		clear: left;
	}
	dd {
		margin-left: 1em;
    margin-bottom: 1em;
	}
</style>

<script>
import Tooltip from '@/components/Tooltip'
export default {
  name: 'Home',
  components: {Tooltip},
}
</script>
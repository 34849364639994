<template>
  <div class="home ml-16 mr-16 mt-5">
<h1>CHAPTER I</h1>
<h2>TREATS OF THE PLACE
WHERE OLIVER TWIST
WAS BORN AND OF
THE CIRCUMSTANCES
<Tooltip label="付随する">ATTENDING</Tooltip> HIS BIRTH</h2>
<p>
Among other public buildings in a certain town, which
for many reasons it will be <Tooltip label="分別のある">prudent</Tooltip> to <Tooltip label="[したいことを]差し控える、慎む">refrain</Tooltip> from mentioning, and to which I will assign no fictitious name, there
is one anciently common to most towns, great or small: <Tooltip label="すなわち">to
wit</Tooltip>, a <Tooltip label="[英国の昔の]救貧院">workhouse</Tooltip>; and in this workhouse was born; on a day
and date which I need not trouble myself to repeat, <Tooltip label="…だから">inasmuch as</Tooltip> it can be of no possible consequence to the reader,
in this stage of the business <Tooltip label="いずれにしても">at all events</Tooltip>; the item of mortality whose name is prefixed to the head of this chapter.
For a long time after it was <Tooltip label="案内する">ushered</Tooltip> into this world of
<Tooltip label="は喪失感や失望などによる長期にわたる深い悲しみ. ">sorrow</Tooltip> and trouble, by the parish surgeon, it remained a
matter of considerable doubt whether the child would survive to bear any name at all; in which case it is somewhat
more than probable that these memoirs would never have
appeared; or, if they had, that being comprised within a
couple of pages, they would have possessed the inestimable
merit of being the most concise and faithful specimen of biography, extant in the literature of any age or country.
Although I am not disposed to maintain that the being
born in a workhouse, is in itself the most fortunate and enviable circumstance that can possibly befall a human being,
I do mean to say that in this particular instance, it was the
best thing for Oliver Twist that could by possibility have occurred. The fact is, that there was considerable difficulty in
inducing Oliver to take upon himself the office of respiration,—a troublesome practice, but one which custom has
rendered necessary to our easy existence; and for some time
he lay gasping on a little flock mattress, rather unequally
poised between this world and the next: the balance being decidedly in favour of the latter. Now, if, during this
brief period, Oliver had been surrounded by careful grandmothers, anxious aunts, experienced nurses, and doctors
of profound wisdom, he would most inevitably and indubitably have been killed in no time. There being nobody by,
however, but a pauper old woman, who was rendered rather
misty by an unwonted allowance of beer; and a parish surgeon who did such matters by contract; Oliver and Nature
fought out the point between them. The result was, that, after a few struggles, Oliver breathed, sneezed, and proceeded
to advertise to the inmates of the workhouse the fact of a
new burden having been imposed upon the parish, by set-
ting up as loud a cry as could reasonably have been expected
from a male infant who had not been possessed of that very
useful appendage, a voice, for a much longer space of time
than three minutes and a quarter.
As Oliver gave this first proof of the free and proper
action of his lungs, the patchwork coverlet which was carelessly flung over the iron bedstead, rustled; the pale face of
a young woman was raised feebly from the pillow; and a
faint voice imperfectly articulated the words, ‘Let me see
the child, and die.’
The surgeon had been sitting with his face turned towards the fire: giving the palms of his hands a warm and
a rub alternately. As the young woman spoke, he rose, and
advancing to the bed’s head, said, with more kindness than
might have been expected of him:
‘Oh, you must not talk about dying yet.’
‘Lor bless her dear heart, no!’ interposed the nurse, hastily depositing in her pocket a green glass bottle, the contents
of which she had been tasting in a corner with evident satisfaction.
‘Lor bless her dear heart, when she has lived as long as
I have, sir, and had thirteen children of her own, and all
on ‘em dead except two, and them in the wurkus with me,
she’ll know better than to take on in that way, bless her dear
heart! Think what it is to be a mother, there’s a dear young
lamb do.’
Apparently this consolatory perspective of a mother’s
prospects failed in producing its due effect. The patient
shook her head, and stretched out her hand towards the 
child.
The surgeon deposited it in her arms. She imprinted
her cold white lips passionately on its forehead; passed her
hands over her face; gazed wildly round; shuddered; fell
back—and died. They chafed her breast, hands, and temples; but the blood had stopped forever. They talked of hope
and comfort. They had been strangers too long.
‘It’s all over, Mrs. Thingummy!’ said the surgeon at last.
‘Ah, poor dear, so it is!’ said the nurse, picking up the
cork of the green bottle, which had fallen out on the pillow,
as she stooped to take up the child. ‘Poor dear!’
‘You needn’t mind sending up to me, if the child cries,
nurse,’ said the surgeon, putting on his gloves with great deliberation. ‘It’s very likely it WILL be troublesome. Give it a
little gruel if it is.’ He put on his hat, and, pausing by the bedside on his way to the door, added, ‘She was a good-looking
girl, too; where did she come from?’
‘She was brought here last night,’ replied the old woman,
‘by the overseer’s order. She was found lying in the street.
She had walked some distance, for her shoes were worn to
pieces; but where she came from, or where she was going to,
nobody knows.’
The surgeon leaned over the body, and raised the left
hand. ‘The old story,’ he said, shaking his head: ‘no wedding-ring, I see. Ah! Good-night!’
The medical gentleman walked away to dinner; and the
nurse, having once more applied herself to the green bottle,
sat down on a low chair before the fire, and proceeded to
dress the infant.
What an excellent example of the power of dress, young
Oliver Twist was! Wrapped in the blanket which had hitherto formed his only covering, he might have been the child
of a nobleman or a beggar; it would have been hard for the
haughtiest stranger to have assigned him his proper station
in society. But now that he was enveloped in the old calico robes which had grown yellow in the same service, he
was badged and ticketed, and fell into his place at once—
a parish child—the orphan of a workhouse—the humble,
half-starved drudge—to be cuffed and buffeted through the
world—despised by all, and pitied by none.
Oliver cried lustily. If he could have known that he was
an orphan, left to the tender mercies of church-wardens and
overseers, perhaps he would have cried the louder.
</p>
  </div>
</template>

<style>
  .home{
    text-align: left;
  }
  p {
   text-indent: 1em;
  }
</style>

<script>
import Tooltip from '@/components/Tooltip'
export default {
  name: 'Home',
  components: {Tooltip},
}
</script>
<template>
  <div class="grammaire  ml-16 mr-16 mt-5">
        <p class="orange--text text--darken-4">L'opposition et la concession, activités p. 101</p>

    <h2 class="orange--text text--darken-4">L'opposition et la concession</h2>
    <span class="orange--text text--darken-4">Écoutez et relavez le défi.</span>
    <ol type="A">
      <li>Relevez les cinq mots qui indiquent une opposition.</li>
      <li>Lesquels opposent deux faits ? Lesquels soulignent une contradiction apparente [concession] ?</li>
      <li><Tooltip label="正確にいう">Precisez</Tooltip> leur construction.</li>
    </ol>

    <h2 class="blue--text text--darken-4 mt-5">60. L'opposition et la concession, p. 101</h2>
    «<Tooltip label="…の代わりに">Au lieu de</Tooltip> <Tooltip label="口論">débattre</Tooltip>, venez vous battre !»<br>
    - Pourquoi participez-vous à la marche des mécontents ?<br>
    - C'est tout simple : j'ai un bac + 5 en lettres. Pourtant je suis au <Tooltip label="失業">chômage</Tooltip>. 
    Malgré mes diplôme, impossible de trouver du travail ! <Tooltip label="うんざりだ">Ras le bol</Tooltip> ! 
    J'ai beau <Tooltip label="志願する">postuler</Tooltip> <Tooltip label="どこにでも">partout</Tooltip>, je <Tooltip label="働く、勉強する">bosse</Tooltip> maintenant comme vendeur dans une librairie…
    J'aime beaucoup ça, mais je ne gagne vraiment pas grand chose.<br>
    - En effet, c'est frustrant !
    Mais vous avez <Tooltip label="それでも、それにしても">quand même</Tooltip> du travail. Mon fils, contrairement à vous, ne trouve rien du tout.
    - Et vous alos ? Vous êtes mécontnent parce que ?…
    - J'habite dans un quartier qui se <Tooltip label="悪化する">dégrade</Tooltip> de jour en jour à cause de la <Tooltip label="貧困">pauvreté</Tooltip> et de la violence tandis que nos <Tooltip label="リーダー">dirigeants</Tooltip>, eux, s'enrichissent et vivent dans les beaux quartiers…

    <v-card class="mt-5">
      <v-card-text>
        <span class="orange--text text--darken-4">
          Pour exprimer l'opposition, on peut utiliser :
        </span>
        <ul>
          <li><Tooltip label="[対立、対比]なのに">alors que, tandis que + indicatif</Tooltip></li>
          <li><Tooltip label="反して、逆に">contrairement à + nom</Tooltip></li>
          <li>mais, <Tooltip label="反対に、それどころか">au contraire</Tooltip>, <Tooltip label="反対側に">à l'opposé</Tooltip>, <Tooltip label="それに対して、そのかわりに、その反面">par contre, en revanche</Tooltip></li>
        </ul>
        <span class="orange--text text--darken-4">
          La concession est une forme d'opposition. Elle présente deux idées qui <Tooltip label="かもしれない">peuvent</Tooltip> <Tooltip label="…のように思われる">sembler</Tooltip> <Tooltip label="非論理的">illogiques</Tooltip>.:
        </span><br>
        Pour exprimer la concession, on peut utiliser :
        <ul>
          <li><Tooltip label="たとえ…でも">même si + indicatif</Tooltip></li>
          <li><Tooltip label="…ではあるが、…にもかかわらず">bien que, quoique + subjonctif</Tooltip></li>
          <li><Tooltip label="…にもかかわらず">malgré, en dépit de + nom</Tooltip></li>
          <li><Tooltip label="しかし、ところが">mais, or, pourtant, cepandant, néanmoins, toutefois</Tooltip></li>
        </ul>

      </v-card-text>
    </v-card>

    <h3 class="mt-5">
      7. Pour chaque phrase, dites s'il <Tooltip label="…が主題である">s'agit</Tooltip> d'une opposition ou d'une concession.
    </h3>

    <ol type="1">
      <li>Contrairement à leurs parents, les jeunes estiment que leur génération est « <Tooltip label="犠牲者">sacrifiée</Tooltip> ».</li>
      <li>Bien qu'ils ne <Tooltip label="思いつく">conçoivent</Tooltip> pas de vivre sans travailer, plus de la moitié des jeunes estiment que leurs efforts ne sont pas assez <Tooltip label="報酬">récompensés</Tooltip>.</li>
      <li>Tandis que les jeunes jugent la génération <Tooltip label="前の、先の">précédents</Tooltip> responsable de la crise actuelle, leurs parents s'inquiètent pour leur avenir professionnel.</li>
      <li>Ils sont 85% à penser qu'il y a de plus en plus d'inégalités en France, purtant ils sont peu nombreux à agir <Tooltip label="具体的に、実際に">concrètement</Tooltip> pour y <Tooltip label="改善する">remédier</Tooltip>.</li>
      <li>Quoique les jeunes soient 47% à penser que leur avenir sera <Tooltip label="より悪い">pire</Tooltip> que celui de leurs parents, ils sont 63 % à être optimistes pour l'avnir.</li>
    </ol>
    <small>Source: enquête « Génération quoi ? », 2014</small>

    <h3 class="mt-5">
      8. Écrivez des tweets pour <Tooltip label="報告する、説明する、釈明する">rendre compte des</Tooltip> faits d'<Tooltip label="現代性、今日性">actualité</Tooltip> suivants. Utilisez les mots de l'opposition ou de la concession.
    </h3>

    <ol type="1">
      <li>Résultats du <Tooltip label="意見調査">référendum</Tooltip> : 67 % de « oui » et une <Tooltip label="棄権">abstention</Tooltip> <Tooltip label="記録的な">record</Tooltip></li>
      <li>Fin de la <Tooltip label="ストライキ">grève</Tooltip> : pas de <Tooltip label="引き上げ">revalorisation</Tooltip> de salaire pour les employés</li>
      <li><Tooltip label="請願">Pétition</Tooltip> <Tooltip label="役に立たない、無駄な">inutile</Tooltip> contre la <Tooltip label="改革">réforme</Tooltip> <Tooltip label="憲法の">constitutionnelle</Tooltip></li>
      <li><Tooltip label="デモ">Manifestation</Tooltip> des <Tooltip label="助産婦">sages-femmes</Tooltip> : <Tooltip label="支持">soutien</Tooltip> de la gauche, <Tooltip label="無関心">indifférence</Tooltip> de la droite</li>
      <li><Tooltip label="失敗、挫折">Échec</Tooltip> de la conférence sur le climat</li>
    </ol>

    <h3 class="mt-5">
      9. Vous trouvez que vous êtes mal payé et mal considéré malgré l'important travail que vous <Tooltip label="成し遂げる">fournissez</Tooltip>.
      Vous allez voir votre patron pour <Tooltip label="辞職する">démissionner</Tooltip> en lui expliquant les raisons de votre départ.
      Il essaye de vous retenir.
    </h3>

    <h2 class="orange--text text--darken-4 mt-10">L'<Tooltip label="[文法]先行性">antériorité</Tooltip>, la <Tooltip label="同時性">simultanéité</Tooltip> et la <Tooltip label="時間的に後であること">postériorité</Tooltip></h2>
    <span class="orange--text text--darken-4">Observer et relevez le <Tooltip label="チャレンジ">défi</Tooltip>.</span>

    <p>
      Les françaises se sont <Tooltip label="解放する">émancipées</Tooltip> vers 1920 : après la Grande Guerre, elles ont <Tooltip label="採用する">adopté</Tooltip> des <Tooltip label="服装">tenues</Tooltip> plus confortables et modernes.
      Après que Rochas, Lanvin et Chanel ont <Tooltip label="[人に]服を着せる">habillé</Tooltip> les Françaises durant l'entre-deux-guerres, le <i>New look</i> de Christian Dior <Tooltip label="認められる">s'est imposé</Tooltip>. 
      <Tooltip label="[同時性]…する間に">Tandis que</Tooltip> Coco Chanel <Tooltip label="[habiller 人 de …]人に … を着せる">habillait les femmes d</Tooltip>'un petit <Tooltip label="テーラーメイドの">tailleur</Tooltip> <Tooltip label="[服装]簡素な、きちんとした">strict</Tooltip>,
      Dior <Tooltip label="[商品]世に出す、売り出す">lançait</Tooltip> ses robes « <Tooltip label="花冠">corolle</Tooltip> ». 
      Le look Dior connaît ensuite un succès international avant qu'Yves Saint-Laurent n'impose ses robes droites à partir de 1954.
    </p>
    <ol type="A">
      <li><Tooltip label="見つける、指摘する">Relevez</Tooltip> les <Tooltip label="…のしるし、徴候">indications de</Tooltip> temps.</li>
      <li>Qu'expriment-elles ?
        La simultanéité, l'antériorité, la postériorité ?
      </li>
      <li><Tooltip label="正確に言う">Précisez</Tooltip> le temps des verbes <Tooltip label="使う">employé</Tooltip> dans chaque cas.</li>
    </ol>

    <v-card class="mt-5">
      <v-card-text>
        <span class="orange--text text--darken-4">
          Pour exprimer la simultanété, on peut utiliser :
        </span>
        <ul>
          <li>quand, <Tooltip label="…する時">lorsque</Tooltip> + <Tooltip label="直接法">indicatif</Tooltip> [moment précis] </li>
          <li>alors que, tandis que, pendant que + indicatif [durée] </li>
        </ul>
        <span class="orange--text text--darken-4">
          Pour exprimer l'anériorité, on peut utiliser :
        </span>
        <ul>
          <li><Tooltip label="…が…する前に">avant que</Tooltip> + subjonctif / <Tooltip label="…する前に">avant de</Tooltip> + <Tooltip label="不定詞">infinitif</Tooltip></li>
          <li><Tooltip label="…するまで">jusqu'à ce que</Tooltip> + subjonctif</li>
        </ul>
        <span class="orange--text text--darken-4">
          Pour exprimer la postériorité, on peut utiliser :
        </span>
        <ul>
          <li>après que + indicatif / après + infinitif passé</li>
          <li><Tooltip label="いったん…すると">une fois que + indicatif</Tooltip></li>
          <li><Tooltip label="するやいなや">dès que</Tooltip>, <Tooltip label="するとすぐに">aussitôt que</Tooltip> + indicatif</li>
        </ul>

      </v-card-text>
    </v-card>

    <h3 class="mt-5">
      10. <Tooltip label="しめくくる">Terminez</Tooltip> les phrases suivantes.
    </h3>

    <ol type="1">
      <li>Tu verras sa nouvelle robe <Tooltip label="…する日に[直接法]">le jour où</Tooltip>…</li>
      <li>J'achèterai un vêtement de <Tooltip label="ブランド">marque</Tooltip> avant…</li>
      <li>Il <Tooltip label="身につけている">portera</Tooltip> des cravates au travail <Tooltip label="同じぐらい長く">aussi longtemps que</Tooltip>…</li>
      <li>Nos clients <Tooltip label="高く評価する、良さを認める">appréciaient</Tooltip> nos <Tooltip label="[服]デザイナーの新作">collections</Tooltip> jusqu'à que…</li>
      <li>Il <Tooltip label="[事態が]生じようとしている、起こりそうである">se préparait</Tooltip> pendant que…</li>
    </ol>

    <h3 class="mt-5">
      11. <Tooltip label="結ぶ">Reliez</Tooltip> les deux <Tooltip label="出来事">faits</Tooltip> avec le connecteur de temps de votre choix.
    </h3>

    <ol type="1">
      <li><Tooltip label="開店">Ouverture</Tooltip> d'une maison de haute couture à Lyon / Grand succès de la première collection</li>
      <li>Photographie d'une star portant un vêtement spécial / <Tooltip label="効果">Effet</Tooltip> de mode chez les <Tooltip label="青春期">adolescentes</Tooltip></li>
      <li>Port d'un costume toute la semaine / Port d'un <Tooltip label="ジーンズ">jean</Tooltip> le week-end</li>
    </ol>

    <h3 class="mt-5">
      12. Vous êtes maire et vous <Tooltip label="迎える">accueillez</Tooltip> un grand <Tooltip label="婦人服デザイナー">couturier</Tooltip> en visite dans votre ville. 
      La veille, vous <Tooltip label="詳細に述べる">détaillez</Tooltip> le programme de la journée à un ami.
      Il vous demande des précisions.
    </h3>
    <p class="blue--text text--darken-4">
      E<sub>XEMPLE</sub> : - Dès que je serai debout, j'irai à la mairie pour donner des ordres.<br>
      - Et après ?
    </p>

  </div>
</template>

<script>
import Tooltip from '@/components/Tooltip'
export default {
  name: 'Grammaire',
  components: {Tooltip},
}
</script>

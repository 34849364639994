<template>
  <div class="home ml-16 mr-16 mt-5">

<h1>CHAPITRE I</h1>
<h1>LE SCARABÉE SACRÉ</h1>

<p>Les choses se passèrent ainsi. 
  Nous étions cinq ou six : moi le plus vieux, leur maître, mais encore plus leur compagnon et leur ami ; eux, jeunes gens à cœur chaleureux, à <Tooltip label="のどかな">riante</Tooltip> imagination, <Tooltip label="溢れんばかりの">débordant</Tooltip> de cette <Tooltip label="精気、活力">sève</Tooltip> <Tooltip label="春の">printanière</Tooltip> de la vie qui nous rend si <Tooltip label="開放的な">expansifs</Tooltip> et si désireux de connaître. 
  <Tooltip label="[deviser]打ち解けて話し合う">Devisant</Tooltip> de choses et d'autres, par un <Tooltip label="小道、細道">sentier</Tooltip> <Tooltip label="沿って">bordé d</Tooltip>'<Tooltip label="ニワトコ">hyèbles</Tooltip> et d'<Tooltip label="セイヨウサンザシ">aubépines</Tooltip>, où déjà la <Tooltip label="ハナムグリ">Cétoine dorée</Tooltip> <Tooltip label="[s'enivrer]酔う">s'enivrait</Tooltip> d'amères <Tooltip label="芳香、香気">senteurs</Tooltip> sur les <Tooltip label="散房花序">corymbes</Tooltip> épanouis, on allait voir si le Scarabée sacré avait fait sa première <Tooltip label="登場">apparition</Tooltip> au plateau sablonneux des Angles<a href="#footnote-1"><sup>1</sup></a>, et roulait sa pilule de <Tooltip label="牛糞">bouse</Tooltip>, image du monde pour la vieille Égypte ; on allait s'informer si les eaux vives de la base de la <Tooltip label="丘">colline</Tooltip> n'abritaient point, sous leur tapis de lentilles aquatiques, de jeunes tritons, dont les branchies ressemblent à de menus rameaux de corail ; si l'épinoche, l'élégant petit poisson des ruisselets, avait mis sa cravate de noces, azur et pourpre ; si, de son aile aiguë, l'hiron- delle, nouvellement arrivée, effleurait la prairie, pourchassant les tipules, qui sèment leurs œufs en dansant ; si, sur le seuil d'un terrier creusé dans le grès, le lézard ocellé étalait au soleil sa croupe constellée de taches bleues ; si la mouette rieuse, ve- nue de la mer à la suite des légions de poissons qui remontent le Rhône pour frayer dans ses eaux, planait par bandes sur le fleuve en jetant par intervalles son cri pareil à l'éclat de rire d'un maniaque ; si... mais tenons-nous-en là ; pour abréger, disons que, gens simples et naïfs, prenant un vif plaisir à vivre avec les bêtes, nous allions passer une matinée à la fête ineffable du ré- veil de la vie au printemps.</p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>

<p id="footnote-1">1. Village du Gard, en face d'Avignon.</p>


  </div>
</template>

<style>
  .home{
    text-align: left;
  }
  p {
   text-indent: 1em;
  }
  blockquote {
    position: relative;
    padding: 5px;
    box-sizing: border-box;
    font-style: italic;
  }
  dt {
		margin-top: 1em;
		clear: left;
	}
	dd {
		margin-left: 1em;
    margin-bottom: 1em;
	}
</style>

<script>
import Tooltip from '@/components/Tooltip'
export default {
  name: 'Home',
  components: {Tooltip},
}
</script>
<template>
  <div class="home ml-16 mr-16 mt-5">
<h1>Earnestness</h1>
<h4>December 2020</h4>

<p>Jessica and I have certain words that have special <Tooltip label="意味、意義、重要性">significance</Tooltip> when we're talking about startups. 
The highest <Tooltip label="賛辞">compliment</Tooltip> we can pay to founders is to describe them as "earnest." 
This is not by itself a guarantee of success. 
You could be earnest but incapable. 
But when founders are both formidable (another of our words) and earnest, they're as close to unstoppable as you get.</p>

<p>Earnestness sounds like a boring, even Victorian virtue. 
  It seems a bit of an anachronism that people in Silicon Valley would care about it. 
  Why does this matter so much?</p>

<p>When you call someone earnest, you're making a statement about their motives. 
  It means both that they're doing something for the right reasons, and that they're trying as hard as they can. 
  If we imagine motives as vectors, it means both the direction and the magnitude are right. 
  Though these are of course related: when people are doing something for the right reasons, they try harder. <a href="#footnote-1">[1]</a></p>

<p>The reason motives matter so much in Silicon Valley is that so many people there have the wrong ones. 
  Starting a successful startup makes you rich and famous. 
  So a lot of the people trying to start them are doing it for those reasons. 
  Instead of what? 
  Instead of interest in the problem for its own <Tooltip label="目的">sake</Tooltip>. 
  That is the root of earnestness. <a href="#footnote-2">[2]</a></p>

<p>It's also the <Tooltip label="[顕著な]特徴、特質、純度検証印">hallmark</Tooltip> of a nerd. 
  Indeed, when people describe themselves as "x nerds," what they mean is that they're interested in x for its own sake, and not because it's cool to be interested in x, or because of what they can get from it. 
  They're saying they care so much about x that they're willing to sacrifice seeming cool for its sake.</p>

<p>A <a href="http://www.paulgraham.com/genius.html">genuine interest</a> in something is a very powerful motivator — for some people, the most powerful motivator of all. <a href="#footnote-3">[3]</a> 
Which is why it's what Jessica and I look for in founders. 
But as well as being a source of strength, it's also a source of vulnerability. 
Caring constrains you. 
The earnest can't easily reply <Tooltip label="[かたく]同じもので、同じように[応える、仕返しする]">in kind</Tooltip> to <Tooltip label="あざける">mocking</Tooltip> <Tooltip label="からかい、ひやかし、軽口">banter</Tooltip>, or <Tooltip label="[…を]よそおう">put on</Tooltip> a cool <Tooltip label="[実際とは異なる]外見、体裁、見せかけ">facade</Tooltip>  of <Tooltip label="何事にも動じない">nihil admirari.</Tooltip> 
They care too much. 
They are <Tooltip label="運命づける">doomed</Tooltip> to be the straight man. 
That's a real disadvantage in your <a href="http://www.paulgraham.com/nerds.html">teenage years</a>, when mocking banter and nihil admirari often have <Tooltip label="優勢">the upper hand</Tooltip>. 
But it becomes an advantage later.</p>

<p>It's a <Tooltip label="ごく普通の">commonplace</Tooltip> now that the kids who were nerds in high school become the cool kids' bosses later on. 
But people misunderstand why this happens. 
It's not just because the nerds are smarter, but also because they're more earnest. 
When the problems get harder than the fake ones you're given in high school, caring about them starts to matter.</p>

<p>Does it always matter? Do the earnest always win? Not always. 
It probably doesn't matter much in politics, or in crime, or in certain types of business that are similar to crime, like gambling, personal injury law, patent trolling, and so on. 
Nor does it matter in academic fields at the more <a href="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C5&q=hermeneutic+dialectics+hegemonic+phenomenology+intersectionality">bogus</a> end of the spectrum. 
And though I don't know enough to say for sure, it may not matter in some kinds of humor: it may be possible to be completely cynical and still be very funny.</p>

<p>Interestingly, just as the word "nerd" implies earnestness even when used as a metaphor, the word "politics" implies the opposite. 
It's not only in actual politics that earnestness seems to be a handicap, but also in office politics and academic politics.</p>

<p>Looking at the list of fields I mentioned, there's an obvious pattern. 
Except possibly for humor, these are all types of work I'd avoid like the <Tooltip label="疫病">plague</Tooltip>. 
So that could be a useful heuristic for deciding which fields to work in: how much does earnestness matter? 
Which can in turn <Tooltip label="おそらく">presumably</Tooltip> be inferred from the <Tooltip label="蔓延">prevalence</Tooltip> of nerds at the top.</p>

<p><Tooltip label="[…]に加えて">Along with</Tooltip> "nerd," another word that tends to be associated with earnestness is "naive." 
The earnest often seem <Tooltip label="世間知らずの、騙されやすい">naive</Tooltip>. 
It's not just that they don't have the motives other people have. 
They often don't fully grasp that such motives exist. 
Or they may know intellectually that they do, but because they don't feel them, they forget about them. <a href="#footnote-4">[4]</a></p>

<p>It works to be slightly naive not just about motives but also, believe it or not, about the problems you're working on. 
Naive optimism can <Tooltip label="[…を]埋め合わせる、補填する、補う">ncompensate for</Tooltip> the <Tooltip label="記憶媒体が読めなくなる事">bit rot</Tooltip> that <a href="http://www.paulgraham.com/ecw.html">rapid change</a> causes in established beliefs. 
You <Tooltip label="[…に]没頭する">plunge into</Tooltip> some problem saying "How hard can it be?", and then after solving it you learn that it was till recently insoluble.</p>

<p>Naivete is an obstacle for anyone who wants to seem sophisticated, and this is one reason would-be intellectuals find it so difficult to understand Silicon Valley. 
It hasn't been safe for such people to use the word "earnest" outside scare quotes since Oscar Wilde wrote "The Importance of Being Earnest" in 1895. 
And yet when you zoom in on Silicon Valley, <Tooltip label="[…]に惚れ込んでいる、ぞっこん">right into</Tooltip> <a href="http://www.paulgraham.com/jessica.html">Jessica Livingston's brain</a>, that's what her x-ray vision is seeking out in founders. 
Earnestness! Who'd have guessed? Reporters literally can't believe it when founders making <Tooltip label="山積みの、大量の">piles of</Tooltip> money say that they started their companies to make the world better. 
The situation seems made for mockery. 
How can these founders be so naive as not to realize how <Tooltip label="信じがたい、ありそもない">implausible</Tooltip> they sound?</p>

<p>Though those asking this question don't realize it, that's not a <Tooltip label="[文法]修辞疑問、強意のための疑問文">rhetorical question</Tooltip>.</p>

<p>A lot of founders are faking it, of course, particularly <Tooltip label="ざこ、取るに足らない奴ら">the smaller fry</Tooltip>, and the soon to be smaller fry. 
But not all of them. There are a significant number of founders who really are interested in the problem they're solving mainly for its own sake.</p>

<p>Why shouldn't there be? We have no difficulty believing that people would be interested in history or math or even old bus tickets for their own sake. 
Why can't there be people interested in self-driving cars or social networks for their own sake? 
When you look at the question from this side, it seems obvious there would be. 
And isn't it likely that having a deep interest in something would be a source of great energy and <Tooltip label="弾力">resilience</Tooltip>? It is in every other field.</p>

<p>The question really is why we have a <Tooltip label="盲点">blind spot</Tooltip> about business. 
And the answer to that is obvious if you know enough history. For most of history, making large amounts of money has not been very intellectually interesting. 
In preindustrial times it was never far from <Tooltip label="強盗">robbery</Tooltip>, and some areas of business still <Tooltip label="保つ">retain</Tooltip> that character, except using lawyers instead of soldiers.</p>

<p>But there are other areas of business where the work is <Tooltip label="純粋に">genuinely</Tooltip> interesting. 
Henry Ford got to spend much of his time working on interesting technical problems, and for the last several decades the trend in that direction has been accelerating. 
It's much easier now to make a lot of money by working on something you're interested in than it was <a href="http://www.paulgraham.com/re.html">50 years ago</a>. 
And that, rather than how fast they grow, may be the most important change that startups represent. 
Though indeed, the fact that the work is genuinely interesting is a big part of why it gets done so fast. <a href="#footnote-5">[5]</a></p>

<p>Can you imagine a more important change than one in the relationship between intellectual curiosity and money? 
These are two of the most powerful forces in the world, and in my lifetime they've become significantly more aligned. 
How could you not be fascinated to watch something like this happening in real time?</p>

<p>I meant this essay to be about earnestness generally, and now I've gone and talked about startups again. 
But I suppose at least it serves as an example of an x nerd in the wild.</p>

<h3 class="mb-5">Notes</h3>

<p id="footnote-1">[1] It's interesting how many different ways there are not to be earnest: to be cleverly cynical, to be superficially brilliant, to be conspicuously virtuous, to be cool, to be sophisticated, to be orthodox, to be a snob, to bully, to pander, to be on the make. This pattern suggests that earnestness is not one end of a continuum, but a target one can fall short of in multiple dimensions.
Another thing I notice about this list is that it sounds like a list of the ways people behave on Twitter. Whatever else social media is, it's a vivid catalogue of ways not to be earnest.</p>

<p id="footnote-2">[2] People's motives are as mixed in Silicon Valley as anywhere else. Even the founders motivated mostly by money tend to be at least somewhat interested in the problem they're solving, and even the founders most interested in the problem they're solving also like the idea of getting rich. But there's great variation in the relative proportions of different founders' motivations.
And when I talk about "wrong" motives, I don't mean morally wrong. There's nothing morally wrong with starting a startup to make money. I just mean that those startups don't do as well.</p>

<p id="footnote-3">[3] The most powerful motivator for most people is probably family. But there are some for whom intellectual curiosity comes first. In his (wonderful) autobiography, Paul Halmos says explicitly that for a mathematician, math must come before anything else, including family. Which at least implies that it did for him.</p>

<p id="footnote-4">[4] It's a bigger social error to seem naive in most European countries than it is in America, and this may be one of subtler reasons startups are less common there. Founder culture is completely at odds with sophisticated cynicism.</p>
The most earnest part of Europe is Scandinavia, and not surprisingly this is also the region with the highest number of successful startups per capita.

<p id="footnote-5">[5] Much of business is schleps, and probably always will be. But even being a professor is largely schleps. It would be interesting to collect statistics about the schlep ratios of different jobs, but I suspect they'd rarely be less than 30%.</p>



<p>Thanks to Trevor Blackwell, Patrick Collison, Suhail Doshi, Jessica Livingston, Mattias Ljungman, Harj Taggar, and Kyle Vogt for reading drafts of this.</p>

  </div>
</template>

<style>
  .home{
    text-align: left;
  }
  p {
   text-indent: 1em;
  }
  blockquote {
    position: relative;
    padding: 5px;
    box-sizing: border-box;
    font-style: italic;
  }
  dt {
		margin-top: 1em;
		clear: left;
	}
	dd {
		margin-left: 1em;
    margin-bottom: 1em;
	}
</style>

<script>
import Tooltip from '@/components/Tooltip'
export default {
  name: 'Home',
  components: {Tooltip},
}
</script>